img.center {
  display: block;
  margin: 0 auto;
  padding-bottom: 20%;
}

.block {
  display: block;
  width: 100%;
}

.app-sidebar {
  min-height: calc(100vh - 128px);
}

#root {
  margin: 0;
  height: 100%;
  overflow: auto;
}

/* allows greyed out style to take affect for rules page actions (create, edit, delete) */
.sidebar-label {
  color: unset !important;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sla-user-inputs {
  width: 100%;
}

.feature-list-content .content {
  padding: unset !important;
}
